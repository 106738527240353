import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { Auth } from "aws-amplify";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const CHANGE_TEMP_PWD = "change_temp_pwd";
export const LOGOUT = "logout";
export const FORGOT_FIRST = "forgot_first";
export const FORGOT_SECOND = "forgot_second";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

//sample user to skip login
const dummyUser = {
  token: "energy123",
  name: "test name",
};

//login logout actions
const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {

      Auth.signIn(credentials.email, credentials.password)
        .then((data) => {
          if (data.challengeName == "NEW_PASSWORD_REQUIRED") {
            reject(data);
            //Auth.completeNewPassword(data, credentials.password);
          }
          let user = {};
          user.user = data.username;
          user.token = data.signInUserSession.idToken.jwtToken;

          context.commit(SET_AUTH, user);
          resolve(data);
        })
        .catch((response) => {
          context.commit(SET_ERROR, response);
	  reject(response);
        });
    });
  },
  [CHANGE_TEMP_PWD](context, credentials) {
    return new Promise((resolve, reject) => {

      Auth.completeNewPassword(credentials.challengeUser, credentials.new_password)
        .then((data) => {
          let user = {};
          user.user = data.username;
          user.token = data.signInUserSession.idToken.jwtToken;

          context.commit(SET_AUTH, user);
          resolve(data);
        })
        .catch((response) => {
          context.commit(SET_ERROR, response);
	  reject(response);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve) => {

      Auth.signOut()
        .then(() => {
          context.commit(PURGE_AUTH);
          resolve();
        })
        .catch((response) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FORGOT_FIRST](context, user) {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(user)
        .then(() => {
          resolve();
        })
        .catch((response) => {
          context.commit(SET_ERROR, response);
          reject(response);
        });
    });
  },
  [FORGOT_SECOND](context, credentials) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(credentials.email, credentials.code, credentials.newPass)
        .then((data) => {
          resolve(data);
        })
        .catch((response) => {
          context.commit(SET_ERROR, response);
          reject(response)
        });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      // to activate the proper verify uncomment following lines with proper API
      // ApiService.get("verify")
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data.errors);
      //   });

      //then remove below lines
      context.commit(SET_AUTH, dummyUser);
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
