import Vue from "vue";
import Vuex from "vuex";
import modules from "../../../helpers/storeModules";

Vue.use(Vuex);

export default new Vuex.Store({
  // modules: {
  //   auth,
  //   htmlClass,
  //   config,
  //   breadcrumbs,
  //   profile
  // }
  modules,
});
