import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/layouts/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/Dashboard.vue"),
        },
        {
          path: "/performance/:siteName",
          name: "performance",
          component: () => import("@/views/pages/performance/performance.vue"),
        },
        {
          path: "/reports/:siteName",
          name: "reports",
          component: () => import("@/views/pages/reports/reports.vue"),
        },

        {
          path: "/profile",
          name: "profile",
          component: () => import("@/views/pages/profile/Profile.vue"),
          children: [
            {
              path: "profile-1",
              name: "profile-1",
              component: () => import("@/views/pages/profile/Profile-1.vue"),
            },
            {
              path: "profile-2",
              name: "profile-2",
              component: () => import("@/views/pages/profile/Profile-2.vue"),
            },
          ],
        },
      ],
    },

    {
      path: "/",
      component: () => import("@/views/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/auth/login_pages/Login-1"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/views/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/error/Error-1.vue"),
    },
  ],
});
