import htmlClass from "../core/services/store/htmlclass.module";
import config from "../core/services/store/config.module";
import breadcrumbs from "../core/services/store/breadcrumbs.module";
import auth from "../core/services/store/auth.module";

const requireModule = require.context("../", true, /\.store\.js$/);
const storeModules = {
  auth,
  htmlClass,
  config,
  breadcrumbs,
};

requireModule.keys().forEach((filename) => {
  // create the module name from fileName
  // remove the store.js extension and capitalize
  const moduleName = filename
    .replace(/(\.\/|\.store\.js)/g, "")
    .split("/")
    .pop();
  // .replace(/^\w/, c => c.toUpperCase());

  storeModules[moduleName] =
    requireModule(filename).default || requireModule(filename);
});

export default storeModules;
