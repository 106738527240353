import ApiService from "@/core/services/api.service";

// action types
export const GET_EMS_LIST = "getEmsList";
export const CHANGE_ACTIVE_SITE = "changeActiveSite";
export const CHANGE_SITE_NOTIFICATION = "changeMaintenanceSite";

// mutation types
export const SET_EMS_LIST = "setEmsList";
export const SET_ACTIVE_SITE = "setActiveSite";
export const SET_SITE_NOTIFICATION = "setMaintenanceSite";

const state = {
  emsList: [],
  activeSite: "",
  siteNotification: "",
};

const getters = {
  getEmsList(state) {
    return state.emsList;
  },
  getActiveSite(state) {
    return state.activeSite;
  },
  getSiteNotification(state) {
    return state.siteNotification;
  },
};

const actions = {
  [GET_EMS_LIST](context, user) {
    return new Promise((resolve) => {
      ApiService.query("/api/discovery/site_list_restricted_discovery", {
        params: {
          jwtToken: user.signInUserSession.idToken.jwtToken,
          userName: user.username,
        },
      }).then((res) => {
        context.commit(SET_EMS_LIST, res.data);
        resolve(res);
      });
    });
  },
  [CHANGE_ACTIVE_SITE](context, activeSite) {
    context.commit(SET_ACTIVE_SITE, activeSite);
  },
  [CHANGE_SITE_NOTIFICATION](context, alertText) {
    context.commit(SET_SITE_NOTIFICATION, alertText);
  },
};

const mutations = {
  [SET_EMS_LIST](state, list) {
    state.emsList = list;
  },
  [SET_ACTIVE_SITE](state, activeSite) {
    state.activeSite = activeSite;
  },
  [SET_SITE_NOTIFICATION](state, alertText) {
    state.siteNotification = alertText;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
